import { Component, AfterViewInit } from '@angular/core';
import { PazientiService } from 'src/app/services/pazienti.service';
import { AuthService } from 'src/app/services/auth.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { slideInLeft } from 'ng-animate';

@Component({
  selector: 'app-pazienti',
  templateUrl: './pazienti.component.html',
  styleUrls: ['./pazienti.component.css'],
  animations: [
    trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft))])
  ],
})
export class PazientiComponent implements AfterViewInit {
  slideInLeft: any;
  scrollLoading : boolean = false;
  loading : boolean = false;
  user: any = null;
  pazienti: Array<any> = [];
  index: number = 0;

  constructor(private PazientiService: PazientiService, private AuthService: AuthService) {}

  async ngAfterViewInit(){}

  async ngOnInit(): Promise<void> {
    this.fetchData()
  }

  async fetchData()
  {
    this.loading = true
    await this.AuthService.refresh()
    setInterval(() => {this.AuthService.refresh()}, 300000);
    await this.AuthService.checkToken()
    let user: object | null | undefined = await this.AuthService.getMe()
    this.user = user
    let listaPazienti = await this.PazientiService.list(this.index);
    this.loading = false
    for (let paziente of listaPazienti) {
      this.pazienti.push(paziente);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  async onScrollDown()
  {
    this.scrollLoading = true;
    this.index = this.index + 20
    let listaPazienti = await this.PazientiService.list(this.index);
    this.scrollLoading = false;
    this.loading = false
    for (let paziente of listaPazienti) {
      this.pazienti.push(paziente);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

}
