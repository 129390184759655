import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from  '@angular/common/http';
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})


export class AuthService {
  loginUrl = `${environment.backend_url}/api/dottori/login`
  loginUrlSegreteria = `${environment.backend_url}/api/segretari/login`
  meUrl = `${environment.backend_url}/api/me`
  refreshUrl = `${environment.backend_url}/api/refresh-token`

  constructor(private router: Router, private toastr: ToastrService, private HttpClient: HttpClient) {
    this.getMe()
  }

  async login(email: string, password: string, tipologia: string) {
    let url = (tipologia === "medico") ? this.loginUrl : this.loginUrlSegreteria;
    let homePath = (tipologia === "medico") ? '/dottore/ambulatori' : '/segreteria/calendario';
    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: password,
        tiplogia: tipologia
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    console.log(response)
    if(response.status !== 200) {
      let data = await response.json();
      this.toastr.error(data.error)
      return
    }
    let data = await response.json()
    //console.log(data)
    let refreshToken_Expiration = data.refreshToken_Expiration
    let jwtBearer_Expiration = data.jwtBearer_Expiration
    localStorage.setItem('refreshToken_Expiration', refreshToken_Expiration);
    localStorage.setItem('jwtBearer_Expiration', jwtBearer_Expiration);
    this.toastr.success('Autenticazione effettuata con successo');
    this.router.navigate([homePath]);
  }

  async logout(){
      localStorage.removeItem('jwtBearer_Expiration');
      localStorage.removeItem('refreshToken_Expiration');
      this.toastr.success('Logout effettuato');
      this.router.navigate(['/login']);
  }

  async refresh() {
    console.log('refresh')
    let response = await fetch(this.refreshUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if(response.status !== 200) {
      let data = await response.json();
      this.toastr.error(data.error)
      this.router.navigate(['/login']);
      return
    }
    let data = await response.json()
    //console.log(data)
    let refreshToken_Expiration = data.refreshToken_Expiration
    let jwtBearer_Expiration = data.jwtBearer_Expiration
    localStorage.setItem('refreshToken_Expiration', refreshToken_Expiration);
    localStorage.setItem('jwtBearer_Expiration', jwtBearer_Expiration);
    return;
  }

  async checkToken() {
    let currentEpoch: number = Date.now() / 1000; //epoch in secondi
    var jwtBearer_Expiration: number = parseInt(localStorage.getItem("jwtBearer_Expiration") || '0');
    var refreshToken_Expiration: number = parseInt(localStorage.getItem('refreshToken_Expiration') || "0");
    //check token inesistenti
    if(jwtBearer_Expiration === null || jwtBearer_Expiration === undefined) {
      this.toastr.error('Credenziali scadute', 'Rieffettua l\'autenticazione');
      this.router.navigate(['/login']);
    }
    if(refreshToken_Expiration === null || refreshToken_Expiration === undefined) {
      this.toastr.error('Credenziali scadute', 'Rieffettua l\'autenticazione');
      this.router.navigate(['/login']);
      return
    }
    //refresh token scaduto
    console.log(currentEpoch)
    console.log(jwtBearer_Expiration)
    console.log(refreshToken_Expiration)
    if(currentEpoch >= refreshToken_Expiration) {
      this.toastr.error('Credenziali scadute', 'Rieffettua l\'autenticazione');
      this.router.navigate(['/login']);
    }
    
    //token bearer scaduto ma refresh ancora valido:
    if(currentEpoch >= jwtBearer_Expiration && currentEpoch <= refreshToken_Expiration) {
      console.log('jwt scaduto')
      let refresh = await this.refresh();
      if(refresh === null) {
        this.router.navigate(['/login'])
        return
      }
      await this.checkToken();
    }
    //token bearer sta per scadere tra meno di 15 minuti?
    /*if((currentEpoch - 5*60) >= jwtBearer_Expiration) {
      console.log('jwt quasi scaduto')
      await this.refresh();
      await this.checkToken();
    }*/
    return;
  }

  async getMe() {
    let response = await fetch(this.meUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if(response.status !== 200 && response.status != 401) {
      this.toastr.error("Codice errore: GME 01. Tentare una nuova autenticazione, se il problema persiste contattare il servizio assistenza.")
      this.router.navigate(['/login']);
      return
    }
    if (response.status == 401) {
      return
    }
    let data = await response.json()
    return data
  }
}
