import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/autenticazione/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './views/home/home.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { HttpClientModule } from  '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AmbulatoriComponent } from './views/dottore/ambulatori/ambulatori.component';
import { CalendarioComponent } from './views/dottore/calendario/calendario.component';
import { PrestazioniComponent } from './views/dottore/prestazioni/prestazioni.component';
import { PrescrizioniComponent } from './views/dottore/prescrizioni/prescrizioni.component';
import { PazientiComponent } from './views/dottore/pazienti/pazienti.component';
import { ConfigurazioneComponent } from './views/dottore/configurazione/configurazione.component';
import { ProfiloComponent } from './views/dottore/profilo/profilo.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ProfiloDottoreComponent } from './components/profilo-dottore/profilo-dottore.component';
import { ConfigurazioneDottoreComponent } from './components/configurazione-dottore/configurazione-dottore.component';
import { AggiungiPazientiFormComponent } from './components/aggiungi-pazienti-form/aggiungi-pazienti-form.component';
import { AmbulatorioEditComponent } from './views/dottore/ambulatorio-edit/ambulatorio-edit.component';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ModificaAmbulatorioFormComponent } from './components/modifica-ambulatorio-form/modifica-ambulatorio-form.component';
import { AggiungiAmbulatorioFormComponent } from './components/aggiungi-ambulatorio-form/aggiungi-ambulatorio-form.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    ResetPasswordComponent,
    HomeComponent,
    SidebarComponent,
    AmbulatoriComponent,
    CalendarioComponent,
    PrestazioniComponent,
    PrescrizioniComponent,
    PazientiComponent,
    ConfigurazioneComponent,
    ProfiloComponent,
    PagenotfoundComponent,
    ProfiloDottoreComponent,
    ConfigurazioneDottoreComponent,
    AggiungiPazientiFormComponent,
    AmbulatorioEditComponent,
    ModificaAmbulatorioFormComponent,
    AggiungiAmbulatorioFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    ToastNoAnimation,
    ToastrModule,
    ToastNoAnimationModule.forRoot(),
    HttpClientModule,
    ButtonModule,
    AutoCompleteModule,
    InfiniteScrollModule,
    FullCalendarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
