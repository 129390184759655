import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from  '@angular/common/http';
import {environment} from "src/environments/environment";
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})


export class LocalizzazioniService {
  listaRegioniUrl = `${environment.backend_url}/api/localizzazione/regione/list`
  listaProvinceUrl = `${environment.backend_url}/api/localizzazione/provincia/list`
  listaCittaUrl = `${environment.backend_url}/api/localizzazione/citta/list`

  constructor(private router: Router, private ToastrService: ToastrService, private HttpClient: HttpClient) {}

  async getListaRegioni()
  {
    let url = this.listaRegioniUrl;
    let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      if(response.status !== 200) {
        let data = await response.json();
        this.ToastrService.error(data.error);
        return [];
      }
      let data = await response.json();
      console.log(data);
      return data;
  }

  async getListaProvince()
  {
    let url = this.listaProvinceUrl;
    let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      if(response.status !== 200) {
        let data = await response.json();
        this.ToastrService.error(data.error);
        return [];
      }
      let data = await response.json();
      console.log(data);
      return data;
  }

  async getListaCitta()
  {
    let url = this.listaCittaUrl;
    let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      if(response.status !== 200) {
        let data = await response.json();
        this.ToastrService.error(data.error);
        return [];
      }
      let data = await response.json();
      console.log(data);
      return data;
  }
}
