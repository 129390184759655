import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import itLocale from '@fullcalendar/core/locales/it';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent {
  calendarOptions: CalendarOptions = {
    themeSystem: "default",
    initialView: 'timeGridWeek',
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: true,
      hour12: false,
      meridiem: 'short'
    },
    slotMinTime: '07:00',
    slotMaxTime: "20:00",
    slotDuration: "00:15:00",
    selectable: true,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
    height: "auto",
    locales: [ itLocale ],
    locale: 'it',
    businessHours: [ // specify an array instead
      {
        daysOfWeek: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
        startTime: '08:00', // 8am
        endTime: '18:00' // 6pm
      },
      {
        daysOfWeek: [ 4, 5 ], // Thursday, Friday
        startTime: '10:00', // 10am
        endTime: '16:00' // 4pm
      }
    ],
    events: [
      { title: 'event 1', date: '2024-02-02' },
      { title: 'event 2', date: '2024-02-03' },
      {
        groupId: 'blueEvents', // recurrent events in this group move together
        daysOfWeek: [ '4' ],
        startTime: '10:45:00',
        endTime: '12:45:00',
        className: "bg-emerald-600",
      },
    ],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'timeGridWeek,timeGridDay' // user can switch between the two
    },

    dateClick: function(info) {
      alert('Clicked on: ' + info.dateStr);
      alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      alert('Current view: ' + info.view.type);
      // change the day's background color just for fun
      info.dayEl.style.backgroundColor = 'red';
    },

    select: function(info) {
      console.log(info);
    },

    eventClick: function(info) {
      alert('Event: ' + info.event.title);
    },

    datesSet: function(info)
    {
      console.log(info.view.currentStart)
      console.log(info.view.currentEnd)
    }
  };
  constructor(private AuthService: AuthService){}
  async ngOnInit(): Promise<void> {
    await this.AuthService.checkToken()
  }
}
