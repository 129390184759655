import { Component } from '@angular/core';

@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrls: ['./configurazione.component.css']
})
export class ConfigurazioneComponent {

}
