import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Modal } from 'flowbite'

@Component({
  selector: 'components-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  resetPasswordForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  constructor() {
    this.resetPasswordForm.valueChanges.subscribe((value) => {
    })
  }
  async sendReset(){
    if(this.resetPasswordForm.invalid) {return;}
    const $targetEl = document.getElementById('authentication-modal');
    if ($targetEl !== null) {
      $targetEl.style.display = "none";
    }
    const collection = document.getElementsByClassName("bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40");
    while (collection.length > 0) {
      collection[0].remove();
    }
  }
}