
<div class="p-4 sm:ml-64">
    <div class="p-4 rounded-lg dark:border-gray-700">
       <div class="flex items-center h-48 mb-4 rounded">
            <div class="grid py-6 sm:grid-cols-1">
                <div class="">
                    <p class="font- text-slate-600 mt-6">Modifica le impostazioni relative alle notifiche via mail della tua applicazione.</p>
                </div> 
                <div class="mt-4 flex items-center">
                    <div class="flex flex-col gap-3">
                    <label for="push" class="relative inline-flex cursor-pointer items-center">
                        <input type="checkbox" value="" id="push" class="peer sr-only" />
                        <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Inviami una mail quando un paziente esegue una richiesta di ricetta</span>
                    </label>
                    <label for="email" class="relative inline-flex cursor-pointer items-center">
                        <input type="checkbox" value="" id="email" class="peer sr-only" />
                        <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Inviami una mail quando un paziente prenota una visita</span>
                    </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center mb-4 rounded">
            <div class="grid py-6 sm:grid-cols-1">
                <div class="">
                    <p class="font- text-slate-600 mt-6">Modifica le tue informazioni anagrafiche.</p>
                </div> 
                <div class="mt-4 flex items-center">
                    <div class="flex flex-col gap-3">
                        <form class="w-full max-w-sm">
                            <div class="flex items-center">
                                <input class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" placeholder="Jane Doe" aria-label="Full name">
                                <input class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 ml-4 mr-4" type="text" placeholder="Jane Doe" aria-label="Full name">
                                <button class="flex-shrink-0 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button">
                                    Conferma
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center mb-4 rounded">
            <div class="grid py-6 sm:grid-cols-1">
                <div class="">
                    <p class="font- text-slate-600 mt-6">Modifica le tue credenziali.</p>
                </div> 
                <div class="mt-4 flex items-center">
                    <div class="flex flex-col gap-3">
                        <form class="w-full max-w-sm">
                            <div class="flex items-center">
                                <input class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="password" placeholder="Vecchia password" aria-label="Full name">
                                <input class="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 ml-4 mr-4" type="password" placeholder="Nuova password" aria-label="Full name">
                                <button class="flex-shrink-0 shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button">
                                    Conferma
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
