<div class='fixed bottom-0 w-full'>
    <!--<button class='my-8 float-right px-5 py-2 bg-red-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none'>Back</button>-->
    <button (click)="openModal()" class='bottom-0 my-8 mr-8 float-right px-5 py-2 bg-blue-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none'>
       Nuovo ambulatorio
    </button>
</div>

 <!-- Main modal -->
<div id="aggiungi-ambulatorio-modal" data-modal-target="aggiungi-ambulatorio-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal body -->
            <div class="p-4 md:p-5">
                <form [formGroup]="aggiungiAmbulatorioForm" (submit)="aggiungiAmbulatorio()" class="space-y-4">
                    <div>
                        <input formControlName="name" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Nome della struttura" required>
                    </div>
                    <div>
                         <p-autoComplete formControlName="city" [suggestions]="filteredCities" (completeMethod)="filterCity($event)" field="name" [style]="{'width':'100%', 'border-radius':'5px'}" [inputStyle]="{'width':'100%', 'border-radius':'5px'}"></p-autoComplete>
                     </div>
                    <div>
                        <input formControlName="address" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Indirizzo" required>
                    </div>
                    <button class="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Crea Ambulatorio</button>
                </form>
            </div>
        </div>
    </div>
 </div> 
 <!--fine modale-->