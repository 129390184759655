import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/autenticazione/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { AmbulatoriComponent } from './views/dottore/ambulatori/ambulatori.component';
import { CalendarioComponent } from './views/dottore/calendario/calendario.component';
import { PrestazioniComponent } from './views/dottore/prestazioni/prestazioni.component';
import { PrescrizioniComponent } from './views/dottore/prescrizioni/prescrizioni.component';
import { PazientiComponent } from './views/dottore/pazienti/pazienti.component';
import { ConfigurazioneComponent } from './views/dottore/configurazione/configurazione.component';
import { ProfiloComponent } from './views/dottore/profilo/profilo.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AmbulatorioEditComponent } from './views/dottore/ambulatorio-edit/ambulatorio-edit.component';
const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dottore/ambulatori', component: AmbulatoriComponent},
  {path: 'dottore/ambulatori/:id', component: AmbulatorioEditComponent},
  {path: 'dottore/calendario', component: CalendarioComponent},
  {path: 'dottore/prestazioni', component: PrestazioniComponent},
  {path: 'dottore/prescrizioni', component: PrescrizioniComponent},
  {path: 'dottore/pazienti', component: PazientiComponent},
  {path: 'dottore/configurazione', component: ConfigurazioneComponent},
  {path: 'dottore/profilo', component: ProfiloComponent},
  { path: '**', pathMatch: 'full',  component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
