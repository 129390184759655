 <!-- Main modal -->
 <div id="modifica-ambulatorio-modal" data-modal-target="modifica-ambulatorio-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal body -->
            <div class="p-4 md:p-5">
                <form [formGroup]="modificaAmbulatorioForm" (submit)="modificaAmbulatorio()" class="space-y-4">
                    <input type="hidden" formControlName="id">
                    <div>
                        <label class="text-gray-900 text-sm font-medium pb-2">Nome della struttura</label>
                        <input formControlName="name" id="nomeStruttura" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Nome della struttura" required>
                    </div>
                    <div>
                        <label class="text-gray-900 text-sm font-medium pb-2">Città</label>
                        <p-autoComplete formControlName="city" [suggestions]="filteredCities" (completeMethod)="filterCity($event)" field="name" [style]="{'width':'100%', 'border-radius':'5px'}" [inputStyle]="{'width':'100%', 'border-radius':'5px'}"></p-autoComplete>
                    </div>
                    <div>
                        <label class="text-gray-900 text-sm font-medium pb-2">Indirizzo</label>
                        <input formControlName="address" id="indirizzo" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Indirizzo" required>
                    </div>
                    <button class="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Aggiorna Informazioni</button>
                </form>
            </div>
        </div>
    </div>
 </div> 
 <!--fine modale-->