import { Component, Input } from '@angular/core';
import { Modal } from 'flowbite';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { AmbulatoriService } from 'src/app/services/ambulatori.service';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'components-aggiungi-ambulatorio-form',
  templateUrl: './aggiungi-ambulatorio-form.component.html',
  styleUrls: ['./aggiungi-ambulatorio-form.component.css']
})
export class AggiungiAmbulatorioFormComponent {
  
  aggiungiAmbulatorioForm = new FormGroup({
    name: new FormControl(null, []),
    city: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
  });

  @Input() public regioni: any;
  @Input() public province: any;
  @Input() public citta: any;

  filteredCities: any;

  constructor(private ToastrService: ToastrService, private AmbulatoriService: AmbulatoriService, private AuthService: AuthService) {
    this.aggiungiAmbulatorioForm.valueChanges.subscribe((value) => {})
  }

  async openModal() {
    //alert(1)
    const $targetEl = document.getElementById('aggiungi-ambulatorio-modal');
    const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
    modal.show();
  }

  async closeModal() {
    const $targetEl = document.getElementById('aggiungi-ambulatorio-modal');
    const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(backdrop => {
        (backdrop as HTMLElement).remove()
    });
  }

  async aggiungiAmbulatorio() {
    var name: string = this.aggiungiAmbulatorioForm.controls.name.value ?? "";
    var city: any = this.aggiungiAmbulatorioForm.controls.city.value ?? "";
    var address: string = this.aggiungiAmbulatorioForm.controls.address.value ?? "";
    name = name.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    address = address.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    if (!name || name.length < 3) {
        this.ToastrService.error('Errore: Il nome deve essere una stringa di almeno 3 caratteri');
        return;
    }
    if (!name || name.length < 3) {
        this.ToastrService.error('Errore: Il nome deve essere una stringa di almeno 3 caratteri');
        return;
    }
    if (address.length < 5) {
        this.ToastrService.error('Errore: L\'indirizzo deve essere una stringa di almeno 5 caratteri');
        return;
    }
    this.closeModal()
    let user = await this.AuthService.getMe()
    // crea un nuovo oggetto ambulatorio
    const newAmbulatorio = {
        doctorId: user.id,
        name: name,
        city: city.id,
        address: address
    };
    this.AmbulatoriService.create(newAmbulatorio);
  }

  filterCity(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    this.citta.forEach((citta: any) => {
        if (citta.name.toLowerCase().startsWith(event.query.toLowerCase())) {
            filtered.push(citta);
        }
    });

    this.filteredCities = filtered;
  }
}
