<div class='fixed bottom-0 w-full'>
    <!--<button class='my-8 float-right px-5 py-2 bg-red-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none'>Back</button>-->
    <button (click)="openModal()" class='bottom-0 my-8 mr-8 float-right px-5 py-2 bg-sky-600 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none'>
       Aggiungi Pazienti
    </button>
</div>


 <!-- Main modal -->
<div id="aggiungi-pazienti-modal" data-modal-target="aggiungi-pazienti-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Invia Lista Pazienti
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5">
                <div class="space-y-4" action="#">
                    
                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Messaggio per gli amministratori (facoltativo)</label>
                    <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                    <input (change)="onChange($event)" class="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file">

                    <section [ngSwitch]="status">
                        <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
                        <p *ngSwitchCase="'success'">✅ Done!</p>
                        <p *ngSwitchCase="'fail'">❌ Error!</p>
                        <p *ngSwitchDefault>😶 Waiting to upload...</p>
                    </section>

                    <button (click)="onUpload()" class="w-full mt-4 text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-sky-600 dark:focus:ring-blue-800">Inviare</button>
                </div>
            </div>
        </div>

    </div>
</div> 
 <!--fine main modal-->