import { Component, AfterViewInit, Input } from '@angular/core';
import { AmbulatoriService } from 'src/app/services/ambulatori.service';
import { Modal } from 'flowbite';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'components-modifica-ambulatorio-form',
  templateUrl: './modifica-ambulatorio-form.component.html',
  styleUrl: './modifica-ambulatorio-form.component.css'
})
export class ModificaAmbulatorioFormComponent implements AfterViewInit{
  constructor(private AmbulatoriService: AmbulatoriService, private AuthService: AuthService, private ToastrService: ToastrService){}

  modificaAmbulatorioForm = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl(null, []),
    city: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
  });

  @Input() public regioni: any;
  @Input() public province: any;
  @Input() public citta: any;

  filteredCities: any;

  async ngOnInit(): Promise<void> {
    this.AmbulatoriService.eventEmitter.subscribe((event) => {
      if (event.name === 'ambulatorio-edit-form-open') {
        let ambulatorio = event.value;
        this.modificaAmbulatorioForm.patchValue({ id: ambulatorio.id }) 
        this.modificaAmbulatorioForm.patchValue({ name: ambulatorio.name }) 
        this.modificaAmbulatorioForm.patchValue({ address: ambulatorio.address })
        this.citta.forEach((element: any) => {
          if(ambulatorio.city == element.name) {
            //console.log("eccoci")
            this.modificaAmbulatorioForm.patchValue({ city: element })
          }
        });
        const $targetEl = document.getElementById('modifica-ambulatorio-modal');
        const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
        modal.show();
      }
    });
  }

  ngAfterViewInit(): void {
    // Your implementation here
  }

  async modificaAmbulatorio()
  {
    var id: string = this.modificaAmbulatorioForm.controls.id.value ?? "";
    var name: string = this.modificaAmbulatorioForm.controls.name.value ?? "";
    var city: any = this.modificaAmbulatorioForm.controls.city.value ?? "";
    var address: string = this.modificaAmbulatorioForm.controls.address.value ?? "";

    this.closeModal()
    const newAmbulatorio = {
        id: id,
        name: name,
        city: city.id,
        address: address
    };
    console.log(newAmbulatorio)
    this.AmbulatoriService.update(newAmbulatorio);
  }

  filterCity(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    this.citta.forEach((citta: any) => {
        if (citta.name.toLowerCase().startsWith(event.query.toLowerCase())) {
            filtered.push(citta);
        }
    });

    this.filteredCities = filtered;
  }

  async closeModal() {
    const $targetEl = document.getElementById('modifica-ambulatorio-modal');
    const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(backdrop => {
        (backdrop as HTMLElement).remove()
    });
  }
}
