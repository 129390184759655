import { Component } from '@angular/core';

@Component({
  selector: 'components-configurazione-dottore',
  templateUrl: './configurazione-dottore.component.html',
  styleUrls: ['./configurazione-dottore.component.css']
})
export class ConfigurazioneDottoreComponent {

}
