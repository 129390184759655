import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from  '@angular/common/http';
import {environment} from "src/environments/environment";
import { Ambulatorio } from '../models/Ambulatorio';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class AmbulatoriService {
  eventEmitter: EventEmitter<{name: string, value: any}> = new EventEmitter();

  readUrl = `${environment.backend_url}/api/ambulatori/get/[ID]`;
  listUrl = `${environment.backend_url}/api/ambulatori/list`
  createUrl = `${environment.backend_url}/api/ambulatori/add`
  updateUrl = `${environment.backend_url}/api/ambulatori/put`
  deleteUrl = `${environment.backend_url}/api/ambulatori/delete/{id}`
  ambulatorio = null

  constructor(private router: Router, private ToastrService: ToastrService, private HttpClient: HttpClient) {}

  /**
   * funzione lista
   * @returns lista ambulatori
   */
  async list(): Promise<Ambulatorio[]>
  {
    let response = await fetch(this.listUrl, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include'
    });
    if(response.status !== 200) {
      let data = await response.json();
      this.ToastrService.error(data.error)
      return []
    }
    let data = await response.json()
    return data
  }

  /**
   * 
   * @param id 
   * @returns ambulatorio
   */
  async read(id: string): Promise<Ambulatorio | null> {
    let url = this.readUrl.replace("[ID]", id)
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if(response.status !== 200) {
      let data = await response.json();
      this.ToastrService.error(data.error)
      return null
    }
    return await response.json()
  }

  /**
   * 
   * @param Ambulatorio 
   * @returns ambulatorio
   */
  async create(ambulatorio: any) {
    console.log('inizio create')
    let url = this.createUrl;
    let response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(ambulatorio),
    });

    if(response.status !== 200) {
      let data = await response.json();
      this.ToastrService.error(data.error)
      return
    }
    this.ToastrService.success('Operazione riuscita', 'Ambulatorio creato')
    this.eventEmitter.emit({name: "ambulatorio-creato", value: ambulatorio});
  }

  async update(ambulatorio: any)
  {
    let url = this.updateUrl;
    let response = await fetch(url, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(ambulatorio),
    });

    if(response.status !== 200) {
      let data = await response.json();
      this.ToastrService.error(data.error)
      return
    }
    this.ToastrService.success('Operazione riuscita', 'Ambulatorio aggiornato')
    this.eventEmitter.emit({name: "ambulatorio-aggiornato", value: ambulatorio});
  }

  async delete(id: string)
  {
    let url = this.deleteUrl.replace('{id}', id);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
    });

    if(response.status !== 200) {
      let data = await response.json();
      this.ToastrService.error(data.error)
      return
    }
    this.ToastrService.success('Operazione riuscita', 'Ambulatorio eliminato')
    this.eventEmitter.emit({name: "ambulatorio-eliminato", value: {}});
  }
}
