import { Component } from '@angular/core';


@Component({
  selector: 'app-ambulatorio-edit',
  templateUrl: './ambulatorio-edit.component.html',
  styleUrls: ['./ambulatorio-edit.component.css']
})
export class AmbulatorioEditComponent {
  id: string = "";
  private sub: any;
  constructor() {}
}
