import { Component } from '@angular/core';

@Component({
  selector: 'components-profilo-dottore',
  templateUrl: './profilo-dottore.component.html',
  styleUrls: ['./profilo-dottore.component.css']
})
export class ProfiloDottoreComponent {

}
