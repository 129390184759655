<app-sidebar></app-sidebar>


<div class="p-4 sm:ml-64">
   <div class="p-4 rounded-lg dark:border-gray-700">
       <!--<div class="grid grid-cols-2 gap-4 mb-4">
          <div class="items-center justify-center rounded">    
            <label for="countries" class="mb-4 ml-4 text-sm font-medium text-gray-900 dark:text-white">Durata slot</label>
            <select id="countries" class="bg-gray-50 ml-4 mt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
               <option value="10" selected>10 minuti</option>
               <option value="15">15 minuti</option>
            </select>
          </div>
       </div>-->
      <div class="flex h-64 mb-4 rounded">


         <div class="relative shadow-md sm:rounded-lg">
            <table class="text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
               <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                     <th scope="col" class="p-4">
                           <div class="flex items-center">
                              Erogazione
                           </div>
                     </th>
                     <th scope="col" class="px-6 py-3">
                           Tipologia visita
                     </th>
                     <th scope="col" class="px-6 py-3">
                           Durata
                     </th>
                     <th scope="col" class="px-6 py-3">
                           
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                     <td class="w-4 p-4">
                        <div class="flex items-center">
                           <input checked disabled id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                           <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                        </div>
                     </td>
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Prima visita
                     </th>
                     <td class="px-6 py-4">
                        45 minuti
                     </td>
                     <td class="flex items-center px-6 py-4">
                        <button href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifica</button>
                     </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                     <td class="w-4 p-4">
                        <div class="flex items-center">
                           <input checked disabled id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                           <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                        </div>
                     </td>
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Visita
                     </th>
                     <td class="px-6 py-4">
                        15 minuti
                     </td>
                     <td class="flex items-center px-6 py-4">
                        <button href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifica</button>
                     </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                     <td class="w-4 p-4">
                        <div class="flex items-center">
                           <input disabledid="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                           <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                        </div>
                     </td>
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Certificato ad uso privato
                     </th>
                     <td class="px-6 py-4">
                        30 minuti
                     </td>
                     <td class="flex items-center px-6 py-4">
                        <button href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifica</button>
                     </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                     <td class="w-4 p-4">
                        <div class="flex items-center">
                           <input checked disabled id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                           <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                        </div>
                     </td>
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Vaccinazione antinfluenzale
                     </th>
                     <td class="px-6 py-4">
                        5 minuti
                     </td>
                     <td class="flex items-center px-6 py-4">
                        <button href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifica</button>
                     </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                     <td class="w-4 p-4">
                        <div class="flex items-center">
                           <input disabled id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                           <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                        </div>
                     </td>
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Certificato di idoneità sportiva
                     </th>
                     <td class="px-6 py-4">
                        30 minuti
                     </td>
                     <td class="flex items-center px-6 py-4">
                        <button href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifica</button>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </div>
 </div>
