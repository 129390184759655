import { Component } from '@angular/core';
import { Modal } from 'flowbite';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'components-aggiungi-pazienti-form',
  templateUrl: './aggiungi-pazienti-form.component.html',
  styleUrls: ['./aggiungi-pazienti-form.component.css']
})
export class AggiungiPazientiFormComponent {

  status: "initial" | "uploading" | "success" | "fail" = "initial";
  file: File | null = null; // Variable to store file

  onChange(event: any) {
    const file: File = event.target.files[0];
    console.log('caricamento...')
    if (file) {
      this.status = "initial";
      this.file = file;
    }
  }

  async onUpload() {
    if(this.file) {
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      //const upload$ = this.http.post("https://httpbin.org/post", formData);
      this.status = 'uploading';
    }
    
  }

  aggiungiPrestazioneForm = new FormGroup({
    erogazione: new FormControl(false, []),
    titolo: new FormControl(null, [Validators.required]),
    minuti: new FormControl(null, [Validators.required, Validators.min(5), Validators.max(60)]),
  });

  constructor(private toastr: ToastrService) {
    this.aggiungiPrestazioneForm.valueChanges.subscribe((value) => {
    })
  }

  async openModal() {
    //alert(1)
    const $targetEl = document.getElementById('aggiungi-pazienti-modal');
    const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
    modal.show();
  }

  async closeModal() {
    const $targetEl = document.getElementById('aggiungi-pazienti-modal');
    const modal = new Modal($targetEl, { backdrop: 'dynamic', backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40', closable: true });
    modal.hide();
    const modalBackdrops = document.querySelectorAll('[modal-backdrop]');
    modalBackdrops.forEach(backdrop => {
        (backdrop as HTMLElement).remove()
    });
  }

  async aggiungiPrestazione() {
    var erogazione: boolean = this.aggiungiPrestazioneForm.controls.erogazione.value ?? false;
    var titolo: string = this.aggiungiPrestazioneForm.controls.titolo.value ?? "";
    var minuti: number = this.aggiungiPrestazioneForm.controls.minuti.value ?? 0;
    console.log(erogazione)
    console.log(titolo)
    console.log(minuti)
    if(typeof titolo !== 'string' || !/^[a-zA-Z\s]*$/.test(titolo) || titolo.replace(/\s/g, '').length < 5){
      this.toastr.error('Inserire un titolo valido');
      return;
    }
    if(minuti < 5 || minuti > 90){
      this.toastr.error('Durata della prestazione non valida');
      return;
    }
    titolo = titolo.replace(/\s+/g, ' ')
    titolo = titolo.toUpperCase()

    this.toastr.success('Inserire logica chiamata http per creare prestazione.')

    this.closeModal()
    //esegui chiamata http
  }
}
