import { Component } from '@angular/core';

@Component({
  selector: 'app-prestazioni',
  templateUrl: './prestazioni.component.html',
  styleUrls: ['./prestazioni.component.css']
})
export class PrestazioniComponent {

}
