import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PazientiService {

  constructor() {}

  pazienti: Array<any> = [];
  /**
   * Recupera la lista di tutte le specializzazioni
   */
  async list(index: number)
  {
        this.pazienti = [
            {
                "Id": 1,
                "DoctorId": 123,
                "Fiscal_Code": "ABC123",
                "First_Name": "Mario",
                "Last_Name": "Rossi",
                "Address": "Via Roma 1",
                "BirthDate": "1990-01-01",
                "Created_At": "2022-10-15"
            },
            {
                "Id": 2,
                "DoctorId": 123,
                "Fiscal_Code": "DEF456",
                "First_Name": "Luca",
                "Last_Name": "Bianchi",
                "Address": "Via Milano 2",
                "BirthDate": "1985-05-20",
                "Created_At": "2022-10-15"
            }
        ];

        for (let i = 3; i <= 52; i++) {
            this.pazienti.push({
                "Id": i,
                "DoctorId": 123,
                "Fiscal_Code": "XYZ" + i,
                "First_Name": "Nome" + i,
                "Last_Name": "Cognome" + i,
                "Address": "Via Nuova " + i,
                "BirthDate": "1990-01-01",
                "Created_At": "2022-10-15"
            });
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        let pazienti = this.pazienti.slice(index, index+20);
        return pazienti;
  }
}
