import { Component, AfterViewInit  } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AmbulatoriService } from 'src/app/services/ambulatori.service';
import { Ambulatorio } from 'src/app/models/Ambulatorio';
import { ToastrService } from 'ngx-toastr';
import { LocalizzazioniService } from 'src/app/services/localizzazioni.service';
import { AutoCompleteModule } from 'primeng/autocomplete';

@Component({
  selector: 'views-dottore-ambulatori',
  templateUrl: './ambulatori.component.html',
  styleUrls: ['./ambulatori.component.css'],
})
export class AmbulatoriComponent implements AfterViewInit {
  detailsAmbulatorioUrl = "https://ilmiomedico-test.azurewebsites.net/api/ambulatori/get/[ID]";
  user: object | null | undefined;
  ambulatori: Ambulatorio[] = [];
  loading: boolean = true;
  clipboardAddress: string = "";
  citta: Array<any> = []; 

  constructor(private AuthService: AuthService, 
    private AmbulatoriService: AmbulatoriService, 
    private ToastrService: ToastrService,
    private LocalizzazioniService: LocalizzazioniService){}

  async ngOnInit(): Promise<void> {
    this.fetchData()
    this.AmbulatoriService.eventEmitter.subscribe((event) => {
      if (event.name === 'ambulatorio-creato' || event.name === 'ambulatorio-aggiornato' || event.name === 'ambulatorio-eliminato') {
        this.fetchData();
      }
    });
  }

  async fetchData()
  {
    this.loading = true
    await this.AuthService.refresh()
    setInterval(() => {this.AuthService.refresh()}, 300000);
    await this.AuthService.checkToken()
    let user: object | null | undefined = await this.AuthService.getMe()
    this.user = user

    this.ambulatori = await this.AmbulatoriService.list()
    console.log(this.ambulatori)
    this.loading = false
  }

  async ngAfterViewInit()
  {
    this.citta = await this.LocalizzazioniService.getListaCitta()
  }

  async copyAddressToClipboard(ambulatorio: Ambulatorio)
  {
    this.ToastrService.success('Indirizzo copiato');
    navigator.clipboard.writeText(`${ambulatorio.name}: ${ambulatorio.city}, ${ambulatorio.address}`);
  }

  async editAmbulatorio(ambulatorio: Ambulatorio)
  {
    //console.log('lanciato')
    this.AmbulatoriService.eventEmitter.emit({ name: 'ambulatorio-edit-form-open', value: ambulatorio });
  }

  async delete(id: string)
  {
    this.AmbulatoriService.delete(id);
  }
}