import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'components-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {
  loading: boolean = false;
  loginForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
    tipologia: new FormControl("medico", [Validators.required]),
  });

  constructor(private router: Router, private AuthService: AuthService, private toastr: ToastrService) {
    this.loginForm.valueChanges.subscribe((value) => {})
  }
  async login(){
    this.loading = true;
    await new Promise(resolve => setTimeout(resolve, 100));
    if(this.loginForm.invalid) {this.loading = false; return;}
    let email: string = this.loginForm.controls.email.value ?? "";
    let password: string = this.loginForm.controls.password.value ?? "";
    let tipologia: string = this.loginForm.controls.tipologia.value ?? "";
    await this.AuthService.login(email, password, tipologia);
    this.loading = false;
  }
}
